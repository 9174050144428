.App {
  text-align: center;

}


@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: #B1D7F8;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  height: 300px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, #B1D7F8 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 300px;
  position: absolute;
  width: 150px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
          animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 300px;
  width: 300px;
  margin-left: 30px;
}


@media only screen and (max-width: 600px) {
  .slider {
    background: #B1D7F8;
    /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
    height: 300px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 350px;
  }
  .slider::before, .slider::after {
    background: linear-gradient(to right, #B1D7F8 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 300px;
    position: absolute;
    width: 25px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    -webkit-animation: scroll 40s linear infinite;
            animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slider .slide {
    height: 300px;
    width: 300px;
    margin-left: 30px;
  }
}


.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline {
  height: 300px;
  width: 5px;
  background-color: #e5e5e5;
}

.stub1 {
  line-height: 300px;
  font-size: 24px;
  background-color: #eae4e4;
}

.stub2 {
  height: 1000px;
}

.circle {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: #e5e5e5;
}

.circleWrapper {
  position: relative;
}

.rmessage {
  position: absolute;
  top: 20%;
  left: 40px;
  min-width: 150px;
  font-weight: bold;
}

.lmessage {
  position: absolute;
  top: 20%;
  right: 40px;
  min-width: 150px;
  font-weight: bold;
  text-align: right;
}

.rbox {
  position: absolute;
  top: 40px;
  left: 40px;
  min-width: 150px;
  background-color: blue;
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.lbox {
  position: absolute;
  top: 40px;
  right: 40px;
  min-width: 150px;
  text-align: right;
  background-color: blue;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
